import axios from 'axios'

export const ENDPOINT = process.env.API_URL;

const client = axios.create({
  baseURL: ENDPOINT,
  headers: {
    "Content-Type": "application/json",
    "Accept": "application/json",
  },
});

client.interceptors.request.use(res => {
  // @todo store in cookie instead
  const jwt = localStorage.getItem('token');

  if (jwt) {
    res.headers["jwt"] = jwt
  }

  return res;
});

client.interceptors.response.use(
  function (res) {
    return res;
  },
  function (error) {
    if (error.response.status === 401) {
      return window.location.href = '/login';
    }

    if (error.response.status === 403) {
      return error.response;
    }

    return Promise.reject(error);
  });

export default client;
