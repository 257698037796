const colors = {
  white: '#FFFFFF',
  black: '#434343',
  green: '#22CEB6',
  red: '#F83E12',
  orange: '#F88E12',
  blue: '#12A8F8',
  grey: '#F7F7F7',
  greyLight: '#F8F8F8',
  greyLightPanel: '#FAFAFA',
  greyDark: '#D9D9D9',
  greyDarker: '#B2B2B2',
  textGrey: "#707070",
  borderGrey: "#D5D5D5",
  newBlack: "#282828"
};

export default colors;
